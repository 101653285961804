import {Carousel} from 'bootstrap';

function initCarousel() {
    $(window).on('turbo:render load', () => {
        setCarousel();
    });
}

function setCarousel() {
    const carousels = document.querySelectorAll('#carousel');
    carousels.forEach((carousel) => { new Carousel(carousel); });
}

export {initCarousel};
