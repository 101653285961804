import Autocomplete2 from '../autocomplete2_controller';

export default class extends Autocomplete2 {
    connect() {
        super.connect();

        this.inputTarget.addEventListener('click', this.onInputClick);
        this.inputTarget.addEventListener('focusout', this.onInputFocusOut);
        this.inputTarget.addEventListener('keypress', this.onPressEnter);
    }

    onInputClick = () => {
        if (this.hasInputTarget) this.inputTarget.value = '';
    };

    onInputFocusOut = () => {
        if (this.hasInputTarget) this.inputTarget.value = this.hiddenTarget.value;
        this.element.dispatchEvent(new Event('autocomplete.change', {bubbles: true}));
    };

    onPressEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
}
