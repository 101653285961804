import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        waitTime: Number,
        inputId: { type: String, default: 'keywords' }
    }

    request(_event) {
        const input = $(`[id=${this.inputIdValue}]:visible`);
        const form  = $(input).closest('form').get(0);
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            form.requestSubmit();
        }, this.waitTimeValue);
    }
}
