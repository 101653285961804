import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    passwordToggle(event) {
        const element = event.target;
        const input = $(element).siblings('input')[0];
        $(element).toggleClass('fa-eye-slash fa-eye');
        input.type = input.type === 'password' ? 'text' : 'password';
    }
}
