import ApplicationController from '../application_controller'
import {ConfirmSwal} from '../../helpers/swal_helper';

export default class extends ApplicationController {
    static targets = ['imagePreview', 'imageRemover', 'imageField']

    previewImage(event) {
        const reader = new FileReader();

        const imageFile = event.currentTarget.files[0];
        const _this = this;

        $(this.imagePreviewTarget).removeClass('d-none');
        this.imagePreviewTarget.style.objectFit = 'cover';

        if ($(this.imagePreviewTarget).next().hasClass('user-icon')) {
            $(this.imagePreviewTarget).next().addClass('d-none');
        }

        reader.onload = function (e) {
            $(_this.imagePreviewTarget).attr({ src: e.target.result });
        }

        reader.readAsDataURL(imageFile);

        // Add the remove button if it's not already there
        if (this.hasImageRemoverTarget && this.imageRemoverTarget.classList.contains('d-none')) {
            this.imageRemoverTarget.classList.remove('d-none');
        }
    }

    removeImage() {
        this.imagePreviewTarget.classList.add('d-none'); // Hide the image preview
        this.imageRemoverTarget.classList.add('d-none'); // hide the remove button
        this.imageFieldTarget.value = ''; // Clear the file input
    }

    // TODO: use sgid instead
    async purgeImage(event) {
        event.preventDefault();

        const el = event.currentTarget;
        const attachmentId = el.dataset.attachmentId;
        const confirmed = await new ConfirmSwal(JSON.parse(el.dataset.translations)).fire();

        if ( !confirmed || attachmentId === -1 ) { return ;}

        this.stimulate("Attachment#purge", attachmentId)
        this.removeImage(); // Clear DOM
    }
}
