import * as FilePond from 'filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import {setFilePondLocale} from '../plugins/filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {editor} from '../plugins/image_editor';
import {DirectUpload} from '@rails/activestorage';

export const submitBtnSelector = 'input[data-filepond="submit-btn"]';
export const imageSgidSelector = 'input[data-filepond="image-sgid"]';

export class BaseFilePond {
    constructor(element, options) {
        this.firstLoad = true;
        this.element = element;
        this.options = options;

        this.registerFilePondPlugins();
        setFilePondLocale();
        this.pond = this.initializeFilePond(this.element);
        this.setFilePondOptions();

        if (this.options?.preview) {
            this.setPreview();
        }
        if (this.options?.preventSubmit) {
            this.preventSubmit(document.querySelector(submitBtnSelector));
        }
    }

    registerFilePondPlugins() {
        FilePond.registerPlugin(
            FilePondPluginImagePreview,
            FilePondPluginFilePoster,
            FilePondPluginFileValidateType,
            FilePondPluginImageTransform,
        );
    }

    initializeFilePond(element) {
        return FilePond.create(element, {});
    }

    setFilePondOptions() {
        this.pond.setOptions({
            server: {
                headers: {
                    'X-CSRF-Token': document.head.querySelector('[name=\'csrf-token\']')?.content,
                },
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    const uploader = new DirectUpload(file, document.querySelector('meta[name=\'direct-upload-url\']').getAttribute('content'), {
                        directUploadWillStoreFileWithXHR: (request) => {
                            request.upload.addEventListener(
                                'progress',
                                event => progress(event.lengthComputable, event.loaded, event.total),
                            );
                        },
                    });
                    uploader.create((errorResponse, blob) => {
                        if (errorResponse) {
                            error(`Something went wrong: ${errorResponse}`);
                        } else {
                            load(blob.signed_id);
                        }
                    });

                    return {
                        abort: () => abort(),
                    };
                },
                revert: {
                    url: '/filepond/remove',
                },
                load: {
                    url: '/filepond/load?id=',
                },
                restore: {
                    url: '/filepond/load?id=',
                },
            },
        });
    }

    setPreview() {
        const imageSgidEl = document.querySelector(imageSgidSelector);

        if (imageSgidEl) {
            this.pond.files = [
                {
                    source: imageSgidEl.value,
                    options: {
                        type: 'local',
                    },
                },
            ];
        }
    }

    preventSubmit(submitBtn) {
        this.pond.on('addfilestart', () => {
            submitBtn.setAttribute('disabled', 'disabled');
        });
        this.pond.on('addfile', (error, file) => {
            (file.origin !== 1 && this.firstLoad) ? submitBtn.removeAttribute('disabled') : submitBtn.setAttribute('disabled', 'disabled');
            this.firstLoad = false;
        });
        this.pond.on('processfilestart', () => {
            submitBtn.setAttribute('disabled', 'disabled');
        });
        this.pond.on('processfile', () => {
            submitBtn.removeAttribute('disabled');
        });
    }
}

export class BottleFilePond extends BaseFilePond {
    registerFilePondPlugins() {
        super.registerFilePondPlugins();
        FilePond.registerPlugin(FilePondPluginImageEdit);
    }

    initializeFilePond(element) {
        return FilePond.create(element, {
            name: element.name,
            credits: false,
            imageTransformOutputQuality: 60,
            labelIdle: `<div class="fa fa-circle-plus"></div>`,
            allowFilePoster: true,
            imagePreviewMaxHeight: 300,
            imageEditInstantEdit: true,
            allowProcess: false,

            // FilePond Image Editor plugin properties
            imageEditEditor: editor(),
        });
    }
}

export class SessionBannerFilePond extends BaseFilePond {
    registerFilePondPlugins() {
        super.registerFilePondPlugins();
        FilePond.registerPlugin(FilePondPluginImageEdit);
    }

    initializeFilePond(element) {
        return FilePond.create(element, {
            name: element.name,
            credits: false,
            imageTransformOutputQuality: 60,
            labelIdle: `<div class="fa fa-circle-plus"></div>`,
            allowFilePoster: true,
            imagePreviewMaxHeight: 300,
            imageEditInstantEdit: true,
            allowProcess: false,

            // FilePond Image Editor plugin properties
            imageEditEditor: editor({aspectRatio: 5 / 2}),
        });
    }
}
