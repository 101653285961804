import Swal from 'sweetalert2';

export class TSwal extends Swal {
    constructor(translations) {
        super();
        this.translations = translations;
    }
}

export class ConfirmSwal extends TSwal {
    async fire(options) {
        return await Swal.fire(Object.assign(
            {
                title: this.translations.message,
                text: this.translations.sub_message,
                customClass: 'fs-13 w-auto',
                showCancelButton: true,
                confirmButtonText: this.translations.confirm,
                confirmButtonColor: 'var(--bs-primary, #3085d6)',
                cancelButtonText: this.translations.cancel,
                allowOutsideClick: false,
                allowEscapeKey: false
            },
            options))
            .then((result) => {
                return result.isConfirmed;
            });
    }
}

export class InputTextSwal extends TSwal {
    async fire(options) {
        return await Swal.fire(Object.assign(
            {
                input: 'text',
                title: this.translations.title,
                inputPlaceholder: this.translations.placeholder,
                confirmButtonText: this.translations.confirm,
                cancelButtonText: this.translations.cancel,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return this.translations.validation;
                    }
                },
            },
            options));
    }
}

export class HTMLCustomSwal extends TSwal {
    async fire(options) {
        return await Swal.fire(Object.assign(
            {
                title: this.translations.title,
                confirmButtonText: this.translations.confirm,
                cancelButtonText: this.translations.cancel
            },
            options));
    }
}

export class SwitchAccountSwal extends TSwal {
    async fire(options) {
        return await Swal.fire(Object.assign(
            {
                title: this.translations.title,
                input: 'radio',
                width: 'auto',
                inputValidator: (inputValue) => {
                    if (!inputValue) {
                        return this.translations.validation;
                    }
                },
            },
            options));
    }
}

export class SyntaxAlertSwal extends TSwal {
    async fire(options) {
        return await Swal.fire(Object.assign(
            {
                title: this.translations.title,
                text: this.translations.text,
                icon: 'warning',
                confirmButtonColor: 'var(--bs-primary, #3085d6)',
                confirmButtonText: this.translations.confirm
            },
            options));
    }
}

export class RedirectSwal extends Swal {
    constructor(redirectPath) {
        super();
        this.redirectPath = redirectPath ?? '#';
    }

    async fire(options) {
        return await Swal.fire(Object.assign(
            {
                icon: 'error',
                confirmButtonColor: 'var(--bs-primary, #3085d6)',
                confirmButtonText: 'Close',
            },
            options))
            .then((result) => {
                if (result.isConfirmed) {
                    Turbo.visit(this.redirectPath);
                }
            });
    }
}
