import {Modal} from 'bootstrap';
import Cropper from 'cropperjs';

let cropper = null;

export const editor = (options = {}) => {
    return {
        open: (file, _instructions) => {
            const image = document.querySelector('img[data-filepond="image-editor__image"]');
            image.src = URL.createObjectURL(file);

            imageEditorModal().show();

            if (cropper) { // Destroy previous cropper instance
                cropper.destroy();
            }
            cropper = new Cropper(image, Object.assign({
                zoomable: false,
                viewMode: 2,
                autoCropArea: 1,
            }, options));

            return cropper;
        },
        confirm: function (output) {
            this.onconfirm(output);
            if (this.onclose) {
                this.onclose();
            }
        },
        cancel: function () {
            this.oncancel();
            if (this.onclose) {
                this.onclose();
            }
        },
        onconfirm: (output) => {
            imageEditorModal().hide();
            return output;
        },
        oncancel: () => {
            imageEditorModal().hide();
        },
    };
};

export function handleCancel(editor) {
    editor.cancel();
}

export function handleSubmit(editor) {
    editor.confirm(formatCropDataToFilepond());
}

function imageEditorModal() {
    const modal = document.querySelector('.modal[data-filepond="image-editor"]');
    return Modal.getOrCreateInstance(modal, {});
}

function formatCropDataToFilepond() {
    /* Constants. */
    const canvasData = cropper.getCanvasData(); // Cropperjs method getCanvasData()
    const cropData = cropper.getData(); // Cropperjs method getData()

    /* Ratio of selected crop area. */
    const cropAreaRatio = cropData.height / cropData.width;

    /* Center point of crop area in percent. */
    const percentX = (cropData.x + cropData.width / 2) / canvasData.naturalWidth;
    const percentY = (cropData.y + cropData.height / 2) / canvasData.naturalHeight;

    /* Calculate available space round image center position. */
    const cx = percentX > 0.5 ? 1 - percentX : percentX;
    const cy = percentY > 0.5 ? 1 - percentY : percentY;

    /* Calculate image rectangle respecting space round image from crop area. */
    let width = canvasData.naturalWidth;
    let height = width * cropAreaRatio;
    if (height > canvasData.naturalHeight) {
        height = canvasData.naturalHeight;
        width = height / cropAreaRatio;
    }
    const rectWidth = cx * 2 * width;
    const rectHeight = cy * 2 * height;

    /* Calculate zoom. */
    const zoom = Math.max(rectWidth / cropData.width, rectHeight / cropData.height);

    return {
        data: {
            crop: {
                center: {
                    x: percentX,
                    y: percentY,
                },
                flip: {
                    horizontal: cropData.scaleX < 0,
                    vertical: cropData.scaleY < 0,
                },
                zoom: zoom,
                rotation: (Math.PI / 180) * cropData.rotate,
                aspectRatio: cropAreaRatio,
            },
        },
    };
}
