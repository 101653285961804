import {Controller} from '@hotwired/stimulus';
import {Popover} from 'bootstrap';

export default class extends Controller {

    hidePopover(event) {
        // close the popover after 2 seconds
        setTimeout(function () {
            Popover.getInstance($(event.target).closest('button')).hide();
        }, 2000);
    }
}
