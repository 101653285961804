import select2 from 'select2';

export function initSelect2() {
    select2($);
    $.fn.select2.defaults.set('language', 'fr');

    // require language files
    require('select2/dist/js/i18n/fr.js');
    require('select2/dist/js/i18n/en.js');
    require('select2/dist/js/i18n/es.js');
    require('select2/dist/js/i18n/it.js');
    require('select2/dist/js/i18n/pt.js');
}
