import { application } from "../controllers/application"
import controller from "../controllers/application_controller"
import StimulusReflex from "stimulus_reflex"

StimulusReflex.initialize(application, { controller, isolate: true })

// process.env.RAILS_ENV is set by esbuild.config.mjs
if (process.env.RAILS_ENV === "development") {
    StimulusReflex.debug = true
}
