import { Application } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import Autocomplete from "stimulus-autocomplete"

const application = Application.start()
application.register("autocomplete", Autocomplete)

// Configure Stimulus development experience
application.debug = false
application.consumer = consumer
window.Stimulus   = application

export { application }
