import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '../../helpers/axios_helper';
import {formatItem} from '../../helpers/select2_helper';

export default class extends Controller {
    static targets = ['conditionRow', 'selectMethod', 'selectConstraint', 'selectValues'];

    connect() {
        // prevent turbo cache
        Turbo.cache.exemptPageFromCache();

        $(() => {
            // dispatch change event triggered by select2
            $(this.selectMethodTarget).on('select2:select', function() {
                const event = new Event('change', { bubbles: true });
                this.dispatchEvent(event);
            });

            // build select2 on connection
            $(this.selectMethodTarget).select2({ language: window.lang(), placeholder: '' });
            $(this.selectConstraintTarget).select2({ language: window.lang() });
            this.updateSelectValuesByMethod();
        });
    }

    updateValues(_event) {
        this.updateSelectValuesByMethod();

        const data = {
            method: $(this.selectMethodTarget).val(),
            target: $(this.selectValuesTarget).attr('id'),
            locale: window.lang()
        }
        new AxiosRequest('/queries/update_values', 'post', data).sendTurboStream();
    }

    createScrollingSelect(obj) {
        const _this = this;
        obj.select2({
            ajax: {
                url: `/${window.lang()}/items/${$(_this.selectMethodTarget).val().replace('by_', '')}`,
                data: function (params) {
                    return {
                        args: {
                            q: params.term,
                            page: params.page || 1
                        }
                    };
                },
                processResults: function (data) {
                    return data.items;
                },
                dataType: 'json',
                delay: 250,
            },
            minimumInputLength: 3,
            placeholder: '',
            language: window.lang(),
            templateResult: formatItem(_this.selectMethodTarget.value === 'by_country' ? 'country' : ''),
            templateSelection: formatItem(_this.selectMethodTarget.value === 'by_country' ? 'country' : ''),
        });
    }

    updateSelectValuesByMethod() {
        const method = $(this.selectMethodTarget).val();
        switch (method) {
            case 'by_appellation':
            case 'by_region':
            case 'by_country':
                this.createScrollingSelect($(this.selectValuesTarget));
                break;
            default:
                $(this.selectValuesTarget).select2({ placeholder: '', language: window.lang() });
                break;
        }
    }
}
