import {Controller} from '@hotwired/stimulus';
import hotkeys from 'hotkeys-js';

export default class extends Controller {
    static values = {
        keys: { type: Object, default: {} }
    };

    connect() {
        $.each(this.keysValue, function(key, value) {
            hotkeys(key, (event, _handler) => {
                event.preventDefault();
                Turbo.visit(value, { frame: '_top' })
            });
        });
    }

    disconnect() {
        $.each(this.keysValue, function(key, _value) {
            hotkeys.unbind(key);
        });
    }
}
