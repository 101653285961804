// keep track of last visited page
// we use pagehide instead of unload:
// https://web.dev/articles/bfcache?utm_source=lighthouse&utm_medium=devtools&hl=fr#never-use-the-unload-event
const setLastVisited = () => sessionStorage.setItem('lastVisited', window.location.pathname);
window.addEventListener('pagehide', setLastVisited);
window.addEventListener('turbo:before-visit', setLastVisited);

// get current language
function lang() {
    return document.documentElement.lang;
}

// get last visited page
function lastVisited() {
    return sessionStorage.getItem('lastVisited');
}

function pageReloaded() {
    return window.location.pathname === lastVisited();
}

// expose functions to global scope
window.lang = lang;
window.lastVisited = lastVisited;
window.pageReloaded = pageReloaded;
