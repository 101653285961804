import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        multiple: {type: Boolean, default: false},
    };

    connect() {
        this.checked = new Set();
        let _this = this;
        $(this.element).find('input:checked')
            .each((index, checkbox) => {
                _this.checked.add($(checkbox).val());
            });
    }

    check(event) {
        // Get selectable dom and input checkbox
        let checkboxElement = $(event.currentTarget).find(event.params.checkboxElement);
        let checkbox = checkboxElement.prev(); // CAREFUL: Checkbox has to be the previous sibling element

        // If not multiple, uncheck checked items
        if (!this.multipleValue) {
            let checkedItem = $(this.element).find('input:checked').not(checkbox);
            if (checkedItem) {
                checkedItem.prop('checked', false);
            }
        }

        // Select dom
        // 1) Get checkbox and set to right value
        // CAREFUL: Checkbox has to be the previous sibling element
        if (checkbox.is(':checked')) {
            checkbox.prop('checked', false);
            this.checked.delete(checkbox.val());
        } else {
            checkbox.prop('checked', true);
            this.checked.add(checkbox.val());
        }
    }
}
