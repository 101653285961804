import {Controller} from '@hotwired/stimulus';
import * as FilePondHelper from '../helpers/filepond_helper';
import {handleCancel, handleSubmit} from '@js/plugins/image_editor';
import {submitBtnSelector} from '@js/helpers/filepond_helper';

export default class extends Controller {
    static targets = ['field'];

    static values = {
        formatWith: {type: String, default: 'BaseFilePond'},
    };

    pondInstance;

    fieldTargetConnected(element) {
        this.pondInstance = new FilePondHelper[this.formatWithValue](element, {
            preview: true,
            preventSubmit: true,
        }).pond;
    }

    cancelEdition() {
        handleCancel(this.pondInstance.imageEditEditor);
        document.querySelector(submitBtnSelector).removeAttribute('disabled');
    }

    confirmEdition() {
        handleSubmit(this.pondInstance.imageEditEditor);
        document.querySelector(submitBtnSelector).setAttribute('disabled', 'disabled');
    }
}
