export function defaultTemplate(item) {
    return item.text;
}

export function flagTemplate(item) {
    const country = item.element && item.element.dataset['isoCode'] ? item.element.dataset['isoCode'] : item.iso_code;
    return $(`<span><i class='fi fi-${country} me-1'></i> ${item.text}</span>`);
}

export function formatItem(kind) {
    switch (kind) {
        case 'country':
        case 'language':
            return (item) => flagTemplate(item);
        default:
            return (item) => defaultTemplate(item);
    }
}
