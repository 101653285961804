import axios from 'axios';

export class AxiosRequest {
    constructor(url, method, data, headers) {
        this.url = url;
        this.method = method;
        this.params = null; // only used for GET requests
        this.data = null;
        this.headers = headers;
        this.csrfToken = $("meta[name='csrf-token']").attr('content');
        method === 'get' ? this.params = data : this.data = data;
    }

    sendHtml() {
        return axios({
            url: this.url,
            method: this.method,
            data: this.data,
            params: this.params,
            headers: Object.assign(
                {
                    'X-CSRF-Token': this.csrfToken,
                },
                this.headers,
            ),
        });
    }

    sendTurboStream() {
        return axios({
            url: this.url,
            method: this.method,
            data: this.data,
            params: this.params,
            headers: Object.assign(
                {
                    'X-CSRF-Token': this.csrfToken,
                    'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
                },
                this.headers,
                ),
        }).then(response => { return response.data })
            .then(text => Turbo.renderStreamMessage(text));
    }

    sendTurboStreamWithAlternativeRedirection() {
        return axios({
            url: this.url,
            method: this.method,
            data: this.data,
            params: this.params,
            headers: Object.assign(
                {
                    'X-CSRF-Token': this.csrfToken,
                    'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
                },
                this.headers,
                ),
        }).then(response => {
            const contentType = response.headers['content-type'];
            if (contentType.includes('text/vnd.turbo-stream.html')) {
                Turbo.renderStreamMessage(response.data);
            } else if (contentType.includes('application/json')) {
                Turbo.visit(response.data.url);
            } else {
                console.error('Unknown content type');
            }
        })
    }
}
