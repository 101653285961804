import ApplicationController from './application_controller';
import {ConfirmSwal, InputTextSwal} from '../helpers/swal_helper';

export default class extends ApplicationController {
    async join(event) {
        const btn = event.currentTarget;
        const translations = JSON.parse(btn.dataset.translations);
        await new InputTextSwal(translations).fire({
            text: translations.text,
            customClass: 'fs-13',
            inputValidator: (value) => {
                // if value does not match a 6 letter or digit code, return an error message
                if (!value.match(/^[0-9A-Z]{6}$/)) {
                    return translations.validation;
                }
            },
            preConfirm: async (code) => {
                // send a join request to the server and display errors if any
                try {
                    const url = `/${window.lang()}/tasting_sessions/join/${code}`;
                    const response = await fetch(url, {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': document.head.querySelector('[name=\'csrf-token\']')?.content
                        },
                    });
                    if (!response.ok) {
                        return Swal.showValidationMessage((await response.json()).error);
                    }
                    return response.json();
                } catch (error) {
                    return Swal.showValidationMessage(`Request failed: ${error}`);
                }
            }
        }).then(result => {
            if (result.isConfirmed) {
                Turbo.visit(result.value.url); // redirect to the tasting session
            }
        })
    }

    async updateStatus(event) {
        const btn = event.currentTarget;
        const translations = JSON.parse(btn.dataset.translations);
        const confirmed = await new ConfirmSwal(translations).fire({
            text: translations.sub_message,
        });

        if (confirmed) {
            this.stimulate('TastingSession#update_status', btn);
        }
    }
}
