export class Base {
    constructor() {
        this.layout = new Layout();
        this.config = {
            toImageButtonOptions: {
                format: 'png',
                width: 666,
                height: 533,
                scale: 1.5
            },
        };
    }

    plot_on(dom) {
        Plotly.newPlot(dom,
            this.data,
            this.layout.content,
            this.config);
    }

    add_image({
                  href = '', yanchor = 'bottom',
                  x = 0, y = 0,
                  scalex = 0.23, scaley = 0.23,
              }) {
        this.layout.content.images.push({
            xref: 'paper',
            yref: 'paper',
            yanchor: yanchor,
            x: x,
            y: y,
            sizex: scalex,
            sizey: scaley,
            source: href,
        });
    }

    add_text({
                 text = '', yanchor = 'top',
                 font = 'Droid Sans', fontSize = 14, color = '#707070',
                 x = 0.5, y = 1.1,
             }) {
        this.layout.content.annotations.push({
            text: text,
            xref: 'paper',
            yref: 'paper',
            yanchor: yanchor,
            x: x,
            y: y,
            font: {
                size: fontSize,
                family: font,
                color: color,
            },
            showarrow: false,
        });
    }

    download(container) {
        let downloadBtn = $(container).find('a[data-title="Download plot as a png"]');
        downloadBtn[0].click();
    }
}

export class Layout {
    constructor() {
        this.content = {
            margin: {l: 0, r: 0, t: 0, b: 0},
            autosize: true,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            font: {
                size: 14,
                family: 'Droid Sans',
            },
            height: $(document).width() < 600 ? 400 : 750,
            images: [],
            annotations: [{
                xref: 'paper',
                yref: 'paper',
                yanchor: 'bottom',
                x: 1,
                y: 0,
                text: '© Winespace',
                font: {
                    size: 12,
                    family: 'Raleway',
                    color: '#707070',
                },
                showarrow: false,
            }],
        };
    }

    add(data) {
        this.content = {...this.content, ...data};
    }
}
