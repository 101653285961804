import {Controller} from '@hotwired/stimulus';

const nestedFieldsSelector = '.nested-fields';
const visibleNestedFieldsSelector = '.nested-fields input[id*="destroy"][value="false"]';

export default class extends Controller {
    static targets = ['links', 'template'];

    static values = {
        allowEmpty: {type: Boolean, default: true},
        maxFields: {type: Number, default: Infinity},
    };

    removeNestedField(event) {
        event.preventDefault();

        const fieldsCount = document.querySelectorAll(nestedFieldsSelector).length;
        if (!this.allowEmptyValue && fieldsCount === 1) {
            toastr.error(this.element.dataset.allowEmptyError);
            return;
        }

        const wrapper = event.target.closest('.nested-fields');
        const nestedId = this.nestedId(wrapper);
        if (wrapper.dataset.newRecord === 'true') {
            wrapper.remove();
            this.element.dispatchEvent(new CustomEvent('nested-field:removed', {
                    detail: {wrapper: wrapper, nestedId: nestedId},
                },
            ));
        } else {
            wrapper.querySelector('input[name*=\'_destroy\']').value = 1;
            wrapper.style.display = 'none';
            this.element.dispatchEvent(new CustomEvent('nested-field:hidden', {
                    detail: {wrapper: wrapper, nestedId: nestedId},
                },
            ));
        }
    }

    addNestedField(event) {
        event.preventDefault();

        const fieldsCount = document.querySelectorAll(visibleNestedFieldsSelector).length;
        if (fieldsCount >= this.maxFieldsValue) {
            toastr.error(this.element.dataset.maxFieldsError);
            return;
        }

        const nestedId = new Date().getTime().toString();
        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, nestedId);
        this.linksTarget.insertAdjacentHTML('beforebegin', content);
        this.element.dispatchEvent(new CustomEvent('nested-field:added', {
                detail: {nestedId: nestedId},
            },
        ));
    }

    nestedFieldValues(nestedField) {
        let res = {};
        nestedField.querySelectorAll('input, select, textarea').forEach((field) => {
            const fieldName = this.nestedFieldName(field);
            if (fieldName !== null) res[fieldName] = field.value.toString();
        });
        return res;
    }

    // Extract the nested field name from the input name
    // We suppose that the input name is formatted like this
    // e.g. name="comment[comments_attributes][0][content]" => "content"
    nestedFieldName(nestedField) {
        const regex = nestedField.name.match(/.*\[\d+]\[(.*)]/);
        return regex !== null ? regex[1] : regex;
    }

    nestedId(nestedField) {
        return nestedField.dataset.nestedId?.toString();
    }
}
