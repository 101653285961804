import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [ 'collapseButton', 'collapsedElement', 'truncatedElement' ]

    connect() {
        if (!this.isOverflowing(this.truncatedElementTarget)) this.collapseButtonTarget.classList.add('d-none');
    }

    collapse(e) {
        e.stopPropagation();

        let multiToggle = function (el, classes) {
            classes.forEach(c => el.classList.toggle(c));
        }

        const el = this.collapsedElementTarget;
        multiToggle(el.querySelector('.fst-italic'), ['col', 'col-12', 'text-truncate']);
        multiToggle(el.querySelector('.col-auto'), ['ms-auto']);
        multiToggle(el.querySelector('.btn-collapse'), ['fa-chevron-up', 'fa-chevron-down']);
    }

    isOverflowing(element) {
        return element.scrollWidth > element.offsetWidth;
    }
}
