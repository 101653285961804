import {Controller} from '@hotwired/stimulus';
import { AxiosRequest } from '../helpers/axios_helper';
import {SwitchAccountSwal} from '../helpers/swal_helper';

export default class extends Controller {
    static values = {
        accounts: { type: Object, default: {} },
        current: { type: Number, default: 0 },
        path: { type: String, default: '/' }
    }

    async switch(event) {
        event.preventDefault();
        const accountIds = Object.keys(this.accountsValue);

        if (accountIds.length === 2) {
            const nextAccount = accountIds.find(id => id !== this.currentValue.toString());
            this.switchAccountAndRedirect(nextAccount);
        } else {
            const translations = JSON.parse(event.currentTarget.dataset.translations);
            const { value: account } = await new SwitchAccountSwal(translations).fire({inputOptions: this.accountsValue});

            if (account) {
                this.switchAccountAndRedirect(account);
            }
        }
    }

    switchAccountAndRedirect(account) {
        new AxiosRequest('/set_current_employee', 'post', { employee_id: account })
            .sendHtml()
            .then(
                () => {
                    window.location.href = this.pathValue;
                }
            );
    }
}
