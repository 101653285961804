import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { English } from "flatpickr/dist/l10n/default.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

function fetchLocale(locale) {
    switch (locale) {
        case 'fr':
            return French;
        case 'en':
            return English;
        case 'es':
            return Spanish;
        case 'it':
            return Italian;
        case 'pt':
            return Portuguese;
        default:
            return French;
    }
}

export function setFlatpickrLocale() {
    flatpickr.localize(fetchLocale(window.lang()));
}
