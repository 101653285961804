import {Controller} from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
    static values = {identifier: String};

    identifierValueChanged() {
        // Reconnect the consumer to the correct identifier
        consumer.disconnect();
        consumer.connect();
    }
}
