import * as FilePond from 'filepond';
import fr from 'filepond/locale/fr-fr';
import en from 'filepond/locale/en-en';
import es from 'filepond/locale/es-es';
import it from 'filepond/locale/it-it';
import pt from 'filepond/locale/pt-pt';

function fetchLocale(locale) {
    switch (locale) {
        case 'fr':
            return fr;
        case 'en':
            return en;
        case 'es':
            return es;
        case 'it':
            return it;
        case 'pt':
            return pt;
        default:
            return fr;
    }
}

export function setFilePondLocale() {
    FilePond.setOptions(fetchLocale(window.lang()));
}
