export class Cache {
    constructor(key) {
        this.key = key;
    }

    store() {
        sessionStorage.setItem(this.key, JSON.stringify([...this.storage]));
    }

    clear() {
        sessionStorage.removeItem(this.key);
    }
}

export class KeyValueCache extends Cache {
    constructor(key) {
        super(key);
        this.storage = sessionStorage.hasOwnProperty(key) ? JSON.parse(sessionStorage.getItem(key)) : {};
    }

    store() {
        sessionStorage.setItem(this.key, JSON.stringify(this.storage));
    }

    set(key, value, nestedId = null) {
        if (nestedId === null) {
            this.storage[key] = value;
        } else {
            const nestedKeyIdx = this.storage[key].findIndex(obj => obj.nestedId === nestedId);
            if (nestedKeyIdx === -1) {
                this.storage[key].push(value);
            } else {
                this.storage[key][nestedKeyIdx] = value;
            }
        }
    }

    get(key, nestedId = null) {
        if (nestedId === null) {
            return this.storage[key];
        } else {
            return this.storage[key].find(obj => obj.nestedId === nestedId);
        }
    }

    remove(key, nestedId = null) {
        if (nestedId === null) {
            delete this.storage[key];
        } else {
            const nestedKeyIdx = this.storage[key].findIndex(obj => obj.nestedId === nestedId);
            if (nestedKeyIdx !== -1) this.storage[key].splice(nestedKeyIdx, 1);
        }
    }
}

export class FormCache extends Cache {
    constructor(key) {
        super(key);
        this.storage = sessionStorage.hasOwnProperty(key) ? JSON.parse(sessionStorage.getItem(key)) : [];
    }

    set(key, value) {
        this.storage.push({name: key, value: value});
    }

    get(key) {
        return this.storage.find(field => field.name === key)?.value;
    }

    cacheForm(form) {
        let cachedForm = form.serializeArray().filter(input => input.name !== 'authenticity_token');
        cachedForm.push(
            {name: 'url', value: window.location.pathname},
            {name: 'action', value: form.attr('action')},
        );

        sessionStorage.setItem(this.key, JSON.stringify(cachedForm));
    }
}
