import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'toggleElement' ]
    static values  = {
        scrollTop: Boolean,
    }

    hide_or_show() {
        $(this.toggleElementTargets).toggleClass('d-none d-block');
        if (this.scrollTopValue) window.scrollTo(0,0);
    }
}
