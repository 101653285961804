import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '../../helpers/axios_helper';

export default class extends Controller {
    static targets = ['selectableItem', 'counter'];

    static values = {
        checkboxName: {type: String, default: ''},
        withSearch: {type: Boolean, default: false},
        fetchIdsUrl: {type: String, default: ''},
        searchInputId: {type: String, default: '#keywords'},
        submitButtonId: {type: String, default: '#multiselect-submit'},
        selectedItems: {type: Array, default: []},
    };

    static classes = ['selected'];

    initialize() {
        const _this = this;
        this.selectedItems = new Set(this.selectedItemsValue.map(String));

        $(() => {
            this.updateSubmitButton();
            this.updateCounter();
        });

        $(this.element).on('turbo:submit-start', (event) => {
            Array.from(_this.selectedItems)
                .forEach((item) => {
                    event.detail.formSubmission.fetchRequest.body.append(_this.checkboxNameValue, item);
                });
        });
    }

    selectableItemTargetConnected(selectableItem) {
        const checkbox = $(selectableItem).find('.checkbox:visible')
            .find('input');

        if (this.selectedItems.has(checkbox.val())) {
            checkbox.prop('checked', true);
            $(selectableItem).addClass(this.selectedClass);
        }
    }

    toggleSelect(event) {
        const selectableItem = $(event.currentTarget);
        this.toggleSelectItem(selectableItem);
    }

    toggleSelectItem(selectableItem, modes = ['select', 'unselect']) {
        const checkbox = selectableItem.find('.checkbox:visible')
            .find('input');
        // Toggle select
        if (checkbox.is(':checked') && modes.includes('unselect')) {
            checkbox.prop('checked', false);
            this.selectedItems.delete(checkbox.val());
            selectableItem.removeClass(this.selectedClass);
        } else if (modes.includes('select')) {
            checkbox.prop('checked', true);
            this.selectedItems.add(checkbox.val());
            selectableItem.addClass(this.selectedClass);
        }

        this.updateCounter();
        this.updateSubmitButton();
    }

    toggleSelectAll(event) {
        if (this.withSearchValue) {
            let nResults = $('.n_results:first').text();
            nResults = parseInt(nResults) || -1;

            const selectableItemsCount = this.selectableItemTargets.length;

            const _this = this;
            const button = $(event.currentTarget);
            const mode = button.data('mode');

            // Two cases:
            // 1: Number of selectable items is equal to number of results.
            //    In this case, get selectable ids on page

            if (selectableItemsCount === nResults) {
                this.selectableItemTargets.forEach((item) => _this.toggleSelectItem($(item), [mode]));
            } else {
                this.fetchIds([mode]);
            }

            // Change button text
            const inverseMode = mode === 'select' ? 'unselect' : 'select';
            const buttonText = button.data(`text-${inverseMode}`);
            button.data('mode', inverseMode);
            button.text(buttonText);
        }
    }

    updateCounter() {
        const selectedCount = this.selectedItems.size;
        const text = $(this.counterTarget).data(selectedCount < 2 ? 'text-singular' : 'text-plural');

        $(this.counterTarget).text(`${selectedCount} ${text}`);

        if (selectedCount > 0) {
            $('#unselect').addClass('cursor');
        } else {
            $('#unselect').removeClass('cursor');
        }
    }

    updateSubmitButton() {
        const submitButton = $(this.submitButtonIdValue);

        submitButton.prop('disabled', this.selectedItems.size === 0);
    }

    fetchIds(modes) {
        const _this = this;

        const url = new URL(this.fetchIdsUrlValue,
            document.location);

        if (this.withSearchValue) {
            // APPEND Query params if search is
            const searchInput = $(this.searchInputIdValue).val();
            if (searchInput) {
                url.searchParams.append('keywords', searchInput);
            }
        }

        new AxiosRequest(url, 'get').sendHtml()
            .then(response => {
                const data = response.data;

                // CAST ids as String to be sure that comparison are on same types
                const ids = new Set([...data.ids.map(String)]);

                _this.selectedItems = modes.includes('select') ? new Set([..._this.selectedItems, ...ids])
                    : new Set(Array.from(_this.selectedItems)
                        .filter(item => !ids.has(item)));
            })
            .finally(() => {
                _this.selectableItemTargets
                    .forEach((item) => _this.toggleSelectItem($(item), modes));
            });
    }
}
