import {Utils} from 'cable_ready';

const {before, operate, after, processElements} = Utils;

import Swal from 'sweetalert2';
import * as SwalHelper from './helpers/swal_helper.js';
import {KeyValueCache} from './helpers/cache_helper';

export default {
    alert: async operation => {
        const {title, options, swal, swalOptions} = operation;
        const swalClass = swal ? new SwalHelper[swal](swalOptions) : Swal;
        await swalClass.fire(Object.assign({
            title: title,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }, options));
    },

    replaceCacheItemKey: operation => {
        before(document, operation);
        operate(operation, () => {
            const {cacheKey, oldKey, newKey} = operation;
            const cache = new KeyValueCache(cacheKey);
            const value = cache.get(oldKey);
            if (value) {
                cache.remove(oldKey);
                cache.set(newKey, value);
                cache.store();
            }
        });
        after(document, operation);
    },
};
