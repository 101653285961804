import {Base} from './plotly_helper';

export class Sunburst extends Base {
    constructor(data) {
        super();
        // Set sunburst data
        this.data = [{
            type: 'sunburst',
            labels: data.labels,
            values: data.values,
            parents: data.parents,
            level: '',
            leaf: {'opacity': 1},
            marker: {
                line: {
                    width: 1,
                    color: '#fff',
                },
                colors: data.colors,
            },
            branchvalues: 'total',
            hovertemplate: data.hovertemplate,
            showlegend: true,
        }];
    }
}
