import '@hotwired/turbo-rails';
import './controllers';
import './config';
import './channels';

import jquery from 'jquery';

window.$ = jquery;
window.jQuery = jquery;

require('./utils.js');

// plugins
import {initSelect2} from './plugins/select2';

initSelect2();

import initToastr from './plugins/toastr';

initToastr();

import {initCarousel} from './plugins/carousel';

initCarousel();

import {initSweetAlert, setConfirmDialog} from './plugins/sweet_alert';

initSweetAlert();
setConfirmDialog();

import initClipboard from './plugins/clipboard';

initClipboard();
