import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['label'];

    static values = {
        labels: { type: Object, default: {} },
    }

    toggle(event) {
        this.labelTarget.textContent = event.target.checked ? this.labelsValue.checked : this.labelsValue.unchecked;
    }
}
