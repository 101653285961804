import {KeyValueCache} from './cache_helper';

export class NavigationManager extends KeyValueCache {
    constructor() {
        super('navigation');
    }

    // add path to navigation cache with value of current location
    // normalize path by removing language prefix
    setPath(path) {
        this.set(this.removeLangFromPath(path), this.removeLangFromPath(window.location.pathname));
        this.store();
    }

    // get path from navigation cache and add language prefix if found
    getPath(path) {
        const pathValue = this.get(this.removeLangFromPath(path));
        if (pathValue) {
            return this.addLangToPath(pathValue);
        }
    }

    // remove path from navigation cache
    removePath(path) {
        this.remove(this.removeLangFromPath(path));
        this.store();
    }

    // remove current path from navigation cache
    removeCurrentPath() {
        this.removePath(window.location.pathname);
    }

    removeLangFromPath(path = window.location.pathname) {
        if (path.includes(`/${window.lang()}/`)) {
            return `/${path.split(`/${window.lang()}/`)[1]}`;
        } else {
            return path;
        }
    }

    addLangToPath(path = window.location.pathname) {
        return `/${window.lang()}${path}`;
    }
}
