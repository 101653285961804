import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['dropdown', 'icon', 'text'];

    static values = {
        display: {type: String, default: 'block'},
    };

    toggle() {
        if (this.hasIconTarget) {
            $(this.iconTarget).toggleClass('fa-chevron-down fa-chevron-up');
        }

        if (this.hasTextTarget) {
            let btnText = $(this.textTarget).text().trim();
            let textPlus = $(this.textTarget).data('displayTextPlus').trim();

            btnText = btnText === textPlus ? $(this.textTarget).data('displayTextMoins')
                : $(this.textTarget).data('displayTextPlus');

            $(this.textTarget).text(btnText);
        }

        $(this.dropdownTarget).toggleClass(`d-none d-${this.displayValue}`);
    }
}
