import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '../helpers/axios_helper';
import {kebabize} from '../plugins/string';

export default class extends Controller {
    static targets = ["pagination"];

    static values = {
        lastHeader: {type: String, default: 'last_header'},
    }

    scroll() {
        let url = '#';

        if (this.hasPaginationTarget && $(this.element).is(':visible'))
        {
            url  = this.paginationTarget.querySelector("a[href][aria-label='Next']");
            if (url)
            {
                url = url.href;

                const el = $('[data-controller="infinite-scroll"]');
                const wBottom  = $(window).scrollTop() + $(window).height();
                const elBottom = el.offset().top + el.height();

                if (wBottom + 1000 > elBottom){
                    $(this.paginationTarget).remove();
                    this.loadMore(url);
                }
            }
        }
    }

    loadMore(url){
        const _this = this;
        let lastHeader = $(this.element.querySelector(`#${this.lastHeaderValue}`));
        let lastNestedHeader = $(this.element.querySelector('#last_nested_header'));
        let method;
        let params = new FormData();

        if (lastHeader.length > 0) {
            params.append(this.lastHeaderValue, lastHeader.data(kebabize(this.lastHeaderValue)));
            lastHeader.remove();
        }

        if (lastNestedHeader.length > 0) {
            params.append('last_nested_header', lastNestedHeader.data('last-nested-header'));
            lastNestedHeader.remove();
        }

        if (url.includes('search')) {
            method = 'post';
            $('.search-form').each(function(_, form) {
                _this.appendFormToFormData(params, form);
            });
        } else {
            method = 'get';
            params = Object.fromEntries(Array.from(params.entries()));
        }

        new AxiosRequest(url, method, params).sendTurboStream();
    }

    appendFormToFormData(formData, form) {
        const dataToAppend = $(form).serializeArray();
        dataToAppend.forEach(field => {
            formData.append(field.name, field.value);
        });
    }
}
