import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '../../../../javascript/helpers/axios_helper';
import {ConfirmSwal, SyntaxAlertSwal} from '../../../../javascript/helpers/swal_helper';

export default class extends Controller {
    static targets = [
        'cuveeName',
        'domainName',
        'color',
        'wineType',
        'appellationName',
        'hiddenAppellationName',
        'millesimeYear',
        'sampleTypeId',
    ];

    fillInputs(event) {
        const data = event.detail.selected.dataset;
        this.domainNameTarget.value = data.cuveeDomain;
        this.cuveeNameTarget.value = data.cuveeCuvee;
        this.colorTarget.value = data.cuveeColor;
        this.wineTypeTarget.value = data.cuveeWineType;
        this.appellationNameTarget.value = data.cuveeAppellation;
        this.hiddenAppellationNameTarget.value = data.cuveeAppellation;

        // Go to the millesime field
        this.millesimeYearTarget.scrollIntoView();

        // Clear the search field
        document.getElementById('search').value = '';
    }

    async validateStepOne(event) {
        event.preventDefault();

        if (!event.target.form.checkValidity()) {
            event.target.form.reportValidity();
            return;
        }

        const currentTargetDataset = event.currentTarget.dataset;

        // Display a confirmation modal if needed
        if (currentTargetDataset.turboConfirm) {
            const confirmed = await new ConfirmSwal(JSON.parse(currentTargetDataset.turboConfirm)).fire();
            if (!confirmed) {
                return;
            }
        }

        const headers = new Headers({
            'accept': 'application/json',
        });
        const translations = JSON.parse(currentTargetDataset.translations);

        new AxiosRequest(
            '/comments/valid_identification',
            'get',
            {cuvee: this.cuveeNameTarget.value, domain: this.domainNameTarget.value},
            headers)
            .sendHtml()
            .then((response) => {
                    const data = response.data;
                    if (data.values.domain !== null || data.values.cuvee !== null) {
                        this.syntaxAlert(data.values, translations);
                    } else {
                        event.target.form.requestSubmit();
                    }
                },
            );
    }

    syntaxAlert(newValues, translations) {
        new SyntaxAlertSwal(translations).fire().then((result) => {
            if (result.isConfirmed) {
                if (newValues.cuvee !== null) {
                    this.cuveeNameTarget.value = newValues.cuvee;
                }
                if (newValues.domain !== null) {
                    this.domainNameTarget.value = newValues.domain;
                }
            }
        });
    }
}
