import ApplicationController from './application_controller';
import {NavigationManager} from '../helpers/navigation_helper';

export default class extends ApplicationController {
    static targets = ['backButton', 'destroyButton'];

    connect() {
        // if page is accessed via url (no reload, no in-app link), clear path cache
        window.addEventListener('load', (event) => {
            if (document.referrer === '' && !window.pageReloaded()) {
                new NavigationManager().clear();
            }
        });

        super.connect();
    }

    backButtonTargetConnected(element) {
        const path = new NavigationManager().getPath(window.location.pathname);
        if (path) {
            element.href = path;
        }
    }

    destroyButtonTargetConnected(element) {
        const path = new NavigationManager().getPath(window.location.pathname);
        if (path) {
            const url = new URL(element.href);
            url.searchParams.append('redirect_path', path);
            element.href = url.href;
        }
    }

    savePath(event) {
        const path = event.params.pathToReplace ?? event.params.path ?? event.target.pathname;
        if (path !== undefined) {
            const navigationManager = new NavigationManager();
            navigationManager.setPath(path);
        }
    }

    clearPath() {
        new NavigationManager().removeCurrentPath();
    }

    clearPaths() {
        new NavigationManager().clear();
    }
}
