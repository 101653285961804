import Swal from 'sweetalert2';
import {ConfirmSwal} from '../helpers/swal_helper';

export function initSweetAlert() {
    window.Swal = Swal;
}

export function setConfirmDialog() {
    Turbo.setConfirmMethod((message, element) => {
        let translations = JSON.parse(message);

        return new ConfirmSwal(translations).fire();
    });
}
